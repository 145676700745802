<template>
  <div align="center" v-if="!loggingIn" class="mt-2 font-weight-light">
    <v-row class="center mx-1" justify="center">
      <v-col>
        <v-card class="elevation-0" max-width="990">
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-text class="compact-form">
                    <v-snackbar
                      color="amber darken-2"
                      v-model="snackbar"
                      :timeout="4000"
                      top
                    >
                      {{ snackMessage }}
                      <v-btn
                        text
                        small
                        color="success--text"
                        @click.native="snackbar = false"
                        >close</v-btn
                      >
                    </v-snackbar>

                    <v-img
                      class="mb-1"
                      src="/Logo1.png"
                      width="170"
                      height="170"
                    />
                    <v-alert
                      v-if="errorMessage"
                      class="ml-2 mr-2 compact-form"
                      border="right"
                      colored-border
                      type="error"
                      elevation="1"
                      >{{ errorMessage }}</v-alert
                    >
                    <v-form
                      class="grey--text text--darken-3"
                      autocomplete="off"
                      v-if="!loggingIn"
                      @submit.prevent="handleSignIn()"
                    >
                      <v-text-field
                        v-model="model.email"
                        id="email"
                        label="Email"
                        class="font-weight-light body-2"
                        prepend-inner-icon="mdi-email-outline"
                        type="text"
                        color="grey text--darken-3"
                      ></v-text-field>

                      <v-text-field
                        class="font-weight-light body-2"
                        v-model="model.password"
                        id="password"
                        :append-icon="
                          show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                        "
                        :type="show1 ? 'text' : 'password'"
                        label="Password"
                        prepend-inner-icon="mdi-lock-outline"
                        color="grey text--darken-3"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                      <v-btn
                        width="100%"
                        outlined
                        type="submit"
                        class="amber white--text darken-2"
                        >Login</v-btn
                      >
                      <p class="text-center mt-3">Forgot your password?</p>
                    </v-form>
                   
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="2">
            
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { auth } from "../api/auth";
import swal from "sweetalert2";
import { Relay } from "../api/relay";
import { accounts } from "../api/accounts/accounts";
export default {
  data() {
    return {
         step: 1,
    saveMessage: "",
    snackMessage: "",
    snackbar: false,
    // user: "",
    show3: false,
    show2: false,
    show1: false,
    valid: false,
    errorMessage: "",
    errorMessage2: "",
    signingUp: false,
    loggingIn: false,
      model: {
        email: "",
        password: ""
      },
      usersArr: null,
      modalShow: false,
      session: false
    };
  },
  methods: {
    async sendRequest() {
      let token = null;
      let user = {
        email: "admin@undp.org",
        password: "admin429"
      }
      await auth.login(user).then(response => {
        token = response.id;
      });
      await accounts.get(token).then(response => {
        this.usersArr = response.data.filter(u => u.email === this.model.email);
        console.log(this.usersArr, "mmmmmmmmmmmmmmmmmmmmmmm");
      });
      if (this.usersArr.length > 0) {
        Relay.sendMail({
                dodFrom: new Date(),
                      email: this.model.email,
                      status: "passwordReset" 
                      }).then(() => {
                          this.modalShow = !this.modalShow;

      swal({
        text: "The email has been sent. go to your email account to proceed",
        type: "success",
        toast: true,
        position: "middle",
        confirmButtonClass: "btn btn-success btn-fill",
        buttonsStyling: false
      });
                      })
         
      } else {
         swal({
        text: "The user is not valid, re-enter the email",
        type: "error",
        toast: true,
        position: "middle",
        confirmButtonClass: "btn btn-success btn-fill",
        buttonsStyling: false
      });
      }
     
    },
    async openStat() {
      this.modalShow = !this.modalShow;
      
    },
    visibility() {
      const passwordField = document.querySelector("#password");
      if (passwordField.getAttribute("type") === "password")
        passwordField.setAttribute("type", "text");
      else {
        passwordField.setAttribute("type", "password");
      }
    },
    handleSignIn() {
      if (this.model.email !== null && this.model.email !== null) {
        var response = auth.login(this.model);
        response.then(
          response => {
              console.log(response);
            localStorage.jwt = response.id;
            localStorage.jwturole = response.userId;
            localStorage.jwturole = response.Role.name;
            localStorage.user = response.user.district;
            localStorage.userObj = response.user;
            localStorage.jwtuser = response.user.firstName + " " + response.user.lastName;
            // this.$session.start();
            // this.$session.set("jwt", response.id);
            // this.$session.set("jwtuid", response.userId);
            // this.$session.set("jwturole", response.Role.name);
            // this.$session.set("user", response.user.district);
            // this.$session.set("userObj", response.user);

            // this.$session.set(
            //   "jwtuser",
            //   response.user.firstName + " " + response.user.lastName
            // );
            // swal({
            //   title: "",
            //   text: "Signed In Succesfully .",
            //   type: "success",
            //   toast: true,
            //   timer: 2500,
            //   confirmButtonClass: "btn btn-success btn-fill",
            //   buttonsStyling: false,
            //   position: "top-end"
            // });
            this.$router.push({
              path: "/",
              params: {}
            });
          },
          reason => {
            swal({
              text:
                "Failed To Sign In ( possible invalid email and / or password : " +
                reason +
                " )",
              type: "error",
              toast: true,
              position: "top-end",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false
            });
          }
        );
      } else {
        swal({
          text:
            "Failed To Sign In ( possible invalid email and / or password )",
          type: "error",
          toast: true,
          position: "top-end",
          confirmButtonClass: "btn btn-success btn-fill",
          buttonsStyling: false
        });
      }
    }
  },
  mounted() {
    if (typeof this.$route.query.session !== "undefined") {
      swal({
        text:
          "Sorry you dont have an active session. Please signin to access system",
        type: "error",
        toast: true,
        position: "top-end",
        confirmButtonClass: "btn btn-success btn-fill",
        buttonsStyling: false
      });
    }
  }
};
</script>
<style scoped>
.boxhead a {
  text-decoration: none;
}
</style>
