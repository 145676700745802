import axios from 'axios';

// const resource = process.env.VUE_APP_ROOT_API + '/Accounts';
const resource = process.env.VUE_APP_AUTH_URL + '/api/Accounts';



export class accounts
{

  static get (token) {
    return axios.get(resource + '?access_token=' + token).then(response => { return response });
  }
  static async remove (id, token) {
    let response = await axios.delete(resource + '/' + id + '?access_token=' + token).then(response => { return response.data });
    return response;
  }
  static getOne (id, token) {
    return axios.get(resource + '/' + id + '?access_token=' + token).then(response => { return response.data });
  }
  static update (account, token) {
    return axios.patch(resource + '/' + account.id + '?access_token=' + token, account).then(response => { return response.data });
  }
  static async create (id, account) {
    let response = await axios.post(resource + '?access_token=' + id, account).then(response => { return response.data });
    return response;
  }
  static async count (id) {
    let response = await axios.get(resource +  '/count'+'?access_token=' + id).then(response => { return response.data });
    return response;
  }
}
