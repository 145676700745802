import axios from "axios";

const resource = process.env.VUE_APP_RELAY_SERVICE_URL + "/form";

export class Relay {
  static post(data) {
    return axios.post(resource + "/", { data }).then(response => response.data);
  }
  static sendMail(data) {
    return axios
      .post(resource + "/sendmail",  data )
      .then(response => response.data);
  }
}
