import axios from 'axios';

// const resource = process.env.VUE_APP_ROOT_API + '/Accounts';
const resource = process.env.VUE_APP_AUTH_URL + '/api/Accounts';


export class auth
{

  static async login (credentials) {
    let response = await axios.post(resource + '/login?include=User', credentials).then(response => { return response.data });
    return response;
  }

  static logout (session) {
    return axios.post(resource + '/logout?access_token=' + session).then(response => { return response });
  }

  static exists (id, session) {
    return axios.post(resource + '/' + id + '/exists?access_token=' + session).then(response => { return response });
  }

  static myAccount (id, token) {
    return axios.get(resource + '/' + id + '?access_token=' + token).then(response => { return response.data });
  }

  static update (account, token) {
    return axios.patch(resource + '/' + account.id + '?access_token=' + token, account).then(response => { return response.data });
  }

}
